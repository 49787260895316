'use strict';

import '@root/bootstrap';

// User dashboard
import App from './Item.vue';

if (App) {
    new Vue({
        render: h => h(App),
    }).$mount('#main-app');


}
