<template>
    <span>
        {{!!row.hotel ? row.hotel.name : ''}}
        <template v-if="row.agency">({{row.agency.name}})</template>
    </span>
</template>

<script>
import mixin from '../mixin';
export default {
    mixins: [ mixin ],
}
</script>
