import RequestAirlineName from './AirlineName'
import RequestFerryName from './FerryName'
import RequestHotelName from './HotelName'
import RequestTrainName from './TrainName'
import RequestOtherServicedescription from './OtherServiceDescription';

export default {
    RequestAirlineName,
    RequestFerryName,
    RequestHotelName,
    RequestTrainName,
    RequestOtherServicedescription
}
