
export default {
    props: {
        value: {
            type: Object,
            required: true
        },
    },
    
    computed: {
        filters: {
            get: function () { return this.value },
            set: function (value) { this.$emit('input', {...this.value, ...value})}
        }
    }
}
