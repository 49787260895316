<template>
    <div class="level-left">
        <filter-default v-model="filters" v-bind:options="options" placeholders>
            <template slot="after">
                <multiselect
                    v-bind:options="categories"
                    label="value"
                    track-by="key"
                    v-model="category"
                    placeholder="Other Service Type category"
                    class="is-smaller level-item"
                />

                <location-select
                    v-bind:places="false"
                    hasDestination
                    v-model="filters.location"
                    class="level-item is-smaller"
                    placeholder="Area/destination"
                />
            </template>
        </filter-default>
    </div>
</template>

<script>
import value, { options } from '../_mixins';
import FilterDefault from '../Default';
import { Multiselect, LocationSelect } from '@components/form'

export default {
    mixins: [ value, options ],

    components: {
        FilterDefault,
        Multiselect,
        LocationSelect
    },

    computed: {
        categories: function () {
            return this.options.otherServiceTypeCategories ? this.options.otherServiceTypeCategories : []
        },

        category: {
            get: function () { return this.categories.find(category => category.key === this.filters.other.otherServiceTypeCategory) },
            set: function (value) { this.filters.other.otherServiceTypeCategory = !!value ? value.key : null }
        }
    }
}
</script>
