<template>
    <div class="is-white">
        <div v-for="request in requests" v-bind:key="request.id" class="row">
            <div class="cell is-user-cell">
                <i class="material-icons">subdirectory_arrow_right</i>
            </div>
            <div class="cell is-user-cell">
                <user v-bind="{value: request.order.user, row: request}" />
            </div>
            <div class="cell is-small-cell">{{request.order.id}}</div>
            <div class="cell">{{request.order.client.shortName}}</div>
            <div class="cell is-one-quarter">
                <template v-if="request.agency && request.info.provider.name !== ''">{{request.agency.name}} - {{request.info.provider.name}}</template>
                <template v-else-if="request.agency && request.info.provider.name === ''">{{request.agency.name}} - No Name</template>
                <template v-else>{{request.info.provider.name}}</template>
            </div>
            <div class="cell is-smaller-cell">{{request.placeholder.startAt | fullYear }}</div>
            <div class="cell is-smaller-cell">{{request.placeholder.endAt | fullYear }}</div>
            <div class="cell is-medium-cell" v-if="contingent(request)">
                <contingent v-bind="{row: request.order, value: contingent(request) }" />
            </div>
            <div class="cell">
                <!-- Reisestatus -->
                <status v-bind="{row: request.order, value: request.order.currentStatus, options}" />
            </div>
            <div class="cell">{{ getPax(request, 'reservedAmount')}} </div>
            <div class="cell">{{ getPax(request, 'askedAmount') }}</div>
            <div class="cell">
                <status v-bind="{row: request, value: request.requestStatus.status, options}" />
            </div>
            <div class="cell is-medium-cell is-relative">
                <deadline v-bind="{row: request, value: request.optionTodo.dueDate}" />
            </div>
        </div>
    </div>
</template>

<script>
import has from 'lodash/has';
import { fullYear, getValueWithKey } from '@utilities/functions'
import { Contingent, Status, Deadline, User } from '@components/CRUD/Table/Cells'
import { getPaxHotel, getPaxFerry } from './helpers';

export default {
    props: {
        requests: {
            type: Array,
            required: true
        },

        options: {
            type: Object
        }
    },

    methods: {
        requestStatus: function (row, options) {
            if (options && options.requestStatuses) {
                return getValueWithKey({key: row.requestStatus.status, optionList: options.requestStatuses})
            } else {
                return row.requestStatus.status
            }
        },

        requestType: function (request) {
            return request.requestType;
        },

        contingent: function (request) {
            if (has(request, 'ferry')) {
                return request.order.ferryContingent;
            } else {
                return request.order.hotelContingent;
            }
        },

        getPax: function (request, key) {
            const type = this.requestType(request);
            if (type === 'hotel') {
                return getPaxHotel(request, key)
            } else if (type === 'ferry') {
                return getPaxFerry(request, key)
            } else {
                const { doubleRooms, singleRooms, otherRooms } = request.order.hotelContingent.info;

                const totalKey = {
                    reservedAmount: 'totalReserved',
                    askedAmount: 'totalAsked'
                }[key]

                return `${doubleRooms[totalKey]} DZ, ${singleRooms[totalKey]} EZ${otherRooms[totalKey] > 0 ? `, ${otherRooms[totalKey]} MBZ` : ''}`
            }
        }
    },

    filters: {
        fullYear
    },

    components: {
        Contingent, Status, Deadline, User
    }
}
</script>
