<template>
    <span>
        {{row.serviceDescription}}
        <template v-if="agency || name">
            (<template v-if="agency">{{agency}}</template><template v-if="hasSeparator"> - </template><template v-if="name">{{name}}</template>)
        </template>
    </span>
</template>

<script>
import mixin from '../mixin';
export default {
    mixins: [ mixin ],

     computed: {
        agency: function () {
            return !!this.row.agency
                ? this.row.agency.name
                : false;
        },

        name: function () {
            return !!this.row.otherService && !!this.row.otherService.provider
                ? this.row.otherService.provider.name
                : false
        },

        hasSeparator: function () {
            return this.agency && this.name;
        }
    }
}
</script>
