var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _vm._v(
        "\n    " + _vm._s(!!_vm.row.hotel ? _vm.row.hotel.name : "") + "\n    "
      ),
      _vm.row.agency
        ? [_vm._v("(" + _vm._s(_vm.row.agency.name) + ")")]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }