var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _vm._v("\n    " + _vm._s(_vm.row.serviceDescription) + "\n    "),
      _vm.agency || _vm.name
        ? [
            _vm._v("\n        ("),
            _vm.agency ? [_vm._v(_vm._s(_vm.agency))] : _vm._e(),
            _vm.hasSeparator ? [_vm._v(" - ")] : _vm._e(),
            _vm.name ? [_vm._v(_vm._s(_vm.name))] : _vm._e(),
            _vm._v(")\n    "),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }