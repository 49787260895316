<template>
    <div class="todos">
        <div>
            <span>DEADLINES</span>
            <div>
                <button
                    class="button is-large"
                    v-on:click.prevent="openTodoModal"
                >
                    <i class="material-icons">notifications</i> Neue Aufgabe
                </button>

                <user-image
                    v-bind:user="filters['users']"
                    small
                    editable
                    quick
                    v-on:input="changeUsers"
                />
            </div>
        </div>

        <template>
            <div class="is-flex level-left">
                <i class="fa fa-filter" aria-hidden="true"></i>
                <input type="text" class="input" v-model="filters['_search']" placeholder="Nach Aufgabe suchen" />

                <div class="buttons has-addons">
                    <button
                        v-for="status in statuses"
                        v-bind:key="status.value"
                        class="button is-icon"
                        :class="{'is-primary': filters['statuses'].includes(status.value)}"
                        v-on:click.prevent.stop="filterByStatus(status)"
                        v-tooltip.bottom="status.label"
                    ><i class="material-icons">{{status.icon}}</i></button>
                </div>
            </div>

            <div>
                Aufgaben in den nächsten
                <div class="select">
                    <select v-model="filters.days">
                        <option value="3">3 Tagen</option>
                        <option value="7">7 Tagen</option>
                        <option value="30">30 Tagen</option>
                        <option value="365">12 Monaten</option>
                    </select>
                </div>
                <span>{{ pagination.items }}</span>
            </div>
        </template>

        <loading v-if="updateLoading || isLoading" />
        <div class="list" v-else>
            <template v-if="pagination.items > 0">
                <todo
                    v-for="todo in filteredTodos"
                    v-bind:todo="todo"
                    v-bind:key="todo.id"
                    v-on:edit="(todo) => {openTodoModal(todo)}"
                    v-bind:showUser="filters['users'].length > 1"
                />

                <div v-if="pagination.items > 10" class="level py">
                    <div></div>
                    <pagination
                        v-model="pagination"
                        v-on:input="fetchTodos"
                        simple
                    />
                    <div></div>
                </div>
            </template>
            <template v-else>
                <div class="todo">Keine Deadlines vorhanden</div>
            </template>
        </div>

        <todo-form ref="todoModal" showOrder/>
    </div>
</template>



<script>
import moment   from 'moment'
import sortBy   from 'lodash/sortBy';
import debounce from 'lodash/debounce';

import UserImage        from '@components/UserImage';
import Todo             from '@components/Todo';
import Loading          from '@components/Loading';
import { notifyError }  from '@components/Notification';
import { Pagination }   from '@components/CRUD'
import { TodoForm }     from '@components/form';
import { deadlineUrgency, fullYear, timeUntil, dateISOtoView, dateViewtoISO, currentUser, getStorageFilter, setStorageFilter }  from '@utilities/functions';
import { todoStatuses } from '@utilities/variables'
import { getTodos, getUser } from '@api';
import { store }        from '@orders/store';


export default {
    store,


    computed: {
        filteredTodos: function () {
            // const byType = this.todos.filter(todo => !todo.doneDate);
            // const byType = this.todos.filter(todo => this.filters['statuses'].includes(todo.status));
            // const bySearch = this.todos
            //     .filter(todo => todo.name.toLowerCase().indexOf(this.filters['search']) !== -1);

            // const byRange = this.todos.filter(todo => timeUntil(todo.dueDate).days <= this.filterDays);

            return sortBy(this.todos, [
                function (todo) {
                    return dateViewtoISO(todo.dueDate).split('-').join('');
                },
                'id'
            ])
        },


        todos: function () {
            return this.$store.state.todos;
        },
    },


    data: function () {
        return {
            filters: {
                statuses: getStorageFilter('user-dashboard/todos', 'statuses', ['open']),
                users: getStorageFilter('user-dashboard/todos', 'users', [currentUser()]),
                _search: getStorageFilter('user-dashboard/todos', '_search'),
                days: getStorageFilter('user-dashboard/todos', 'days', 30)
            },

            isLoading: false,
            updateLoading: false,

            today: dateISOtoView((new Date()).toISOString()),
            statuses: todoStatuses,

            pagination: {
                current: 1,
                perPage: 10,
                items: 10
            }
        }
    },


    methods: {
        openTodoModal: function (data = {}) {
            this.$refs.todoModal.open(Object.assign({}, {
                user: currentUser()
            }, data));
        },


        changeUsers: function (users) {
            this.filters['users'] = users;
        },

        filterByStatus: function (status) {
            if (this.filters['statuses'].includes(status.value)) {
                this.filters['statuses'] = this.filters['statuses'].filter(value => status.value !== value)
            } else {
                this.filters['statuses'].push(status.value)
            }
        },

        fetchTodos: function () {
            this.isLoading = true;
            return getTodos({
                'user': this.filters['users'].map(user => user.id),
                'status': this.filters['statuses'],
                '_search': this.filters['_search'],
                'dueDate[before]': moment().add(this.filters['days'], 'days').format('DD.MM.YYYY'),
                'order.currentStatus.status.special': 'validForStatuses.status',
                _page: this.pagination.current,
                _itemsPerPage: this.pagination.perPage,
                '_groups': ['order_read', 'client_read', 'trip_read', 'destination_read'],
                '_order[dueDate]': 'asc'
            }).then(response => {
                this.$store.commit('updateTodos', response.data);
                this.pagination = {
                    ...this.pagination,
                    items: Number(response.headers['x-trav-total-items']),
                }
                this.isLoading = false;
            }, error => {
                this.isLoading = false;
            })
        },

        filterTodos: debounce(function () {
            this.isLoading = true;
            this.fetchTodos()
        }, 1000),

        deadlineUrgency,
        timeUntil
    },


    created: function () {
        this.fetchTodos()
    },


    watch: {
        filters: {
            deep: true,
            handler: function (val) {
                setStorageFilter('user-dashboard/todos', val);
                this.filterTodos();
            }
        }
    },


    filters: {
        fullYear
    },


    components: {
        Todo,
        Loading,
        TodoForm,
        UserImage,
        Pagination
    }
}
</script>
