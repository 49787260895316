<template>
   <span>
        <airports-cell v-bind="{row, options, value: row.airline}" />
        <template v-if="agency || name">
            (<template v-if="agency">{{agency}}</template><template v-if="hasSeparator"> - </template><template v-if="name">{{name}}</template>)
        </template>
   </span>
</template>

<script>
import mixin from '../mixin';
import { Airports } from '@components/CRUD/Table/Cells';
export default {
    mixins: [ mixin ],
    components: { AirportsCell: Airports },

    computed: {
        agency: function () {
            return !!this.row.agency
                ? this.row.agency.name
                : false;
        },

        name: function () {
            return !!this.row.airline
                ? this.row.airline.name
                : false
        },

        hasSeparator: function () {
            return this.agency && this.name;
        }
    }
}
</script>
