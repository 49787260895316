<template>
    <div class="level-left">
        <input
            v-if="search"
            type="text"
            v-model="_search"
            class="input is-smaller level-item"
            placeholder="Suchen"
        >

        <slot v-if="placeholders"></slot>

        <async-select
            v-if="requests"
            api="agencies"
            v-model="filters.agency"
            placeholder="Agentur filtern"
            class="is-smaller level-item"
        />

        <template v-if="requests">
            <i class="fa fa-filter fa-lg level-item b-l" ></i>
            <slot />
        </template>

        <slot name="after" v-if="placeholders"></slot>

        <multiselect
            v-model="orderStatus"
            v-bind:options="options.orderStatus ? options.orderStatus : []"
            track-by="key"
            label="value"
            multiple
            class="is-smaller level-item"
            placeholder="Status filtern"
        >
            <template slot="selection" slot-scope="{ values, search, isOpen }">
                <span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} Stati ausgewählt</span>
            </template>
        </multiselect>

        <div class="flatpickr-combined level-item">
            <input-date v-model="filters.startAt" />
            <span>-</span>
            <input-date v-model="filters.endAt" />
        </div>

        <multiselect
            v-if="requests"
            v-model="requestStatus"
            v-bind:options="options.status ? options.status : []"
            multiple
            label="value"
            track-by="key"
            placeholder="L-Status filtern"
            class="is-smaller level-item"
        >
            <template slot="selection" slot-scope="{ values, search, isOpen }">
                <span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} Stati ausgewählt</span>
            </template>
        </multiselect>

        <multiselect
            v-if="placeholders"
            v-bind:options="clientOfferStatuses"
            label="value"
            track-by="key"
            placeholder="im Paket/als Möglichkeit"
            v-model="clientOfferStatus"
            class="is-medium level-item"
        />

        <multiselect
            v-if="placeholders"
            v-bind:options="requestStatuses"
            track-by="value"
            label="label"
            v-model="notRequestStatus"
            class="is-medium level-item"
        />
    </div>
</template>

<script>
import debounce from 'lodash/debounce';
import value, { options } from './_mixins';
import { InputDate, AsyncSelect, Multiselect } from '@components/form'

export default {
    mixins: [ value, options ],

    components: {
        InputDate,
        AsyncSelect,
        Multiselect
    },

    props: {
        search: {
            type: Boolean,
            default: true,
        },

        requests: {
            type: Boolean,
            default: false,
        },

        placeholders: {
            type: Boolean,
            default: false,
        }
    },

    data: function () {
        return {
            requestStatuses: [
                { label: 'noch nicht angefragt', value: 'no_requests' },
                { label: 'noch nicht gebucht', value: 'no_option' },
                { label: 'noch nicht gesichert', value: 'not_approved' },
            ]
        }
    },

    computed: {
        notRequestStatus: {
            get: function () {
                return this.requestStatuses
                    .find(({value}) => this.filters.notRequestStatus === value)
            },
            set: function (status) {
                this.filters.notRequestStatus = !!status ? status.value : null
            }
        },

        _search: {
            get: function () { return this.filters._search },
            set: debounce(function (value) {
                this.$emit('input', {...this.value, _search: value})
            }, 600)
        }
    },
}
</script>
