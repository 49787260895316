<template>
    <tour :steps="steps" :callbacks="callbacks" />
</template>

<script>
export default {
    data: function() {
        return {
            steps: [
                {
                    header: {
                        title: 'Aufgabenübersicht'
                    },
                    target: '.is-user-dashboard .todos .button.is-large',
                    content: '<p>Auf der rechten Seite des Dashbords befinden sich alle Aufgaben, Optionen und Termine - sortiert nach Dringlichkeit.</p>'
                },
                {
                    header: {
                        title: 'Filtermöglichkeit: Nutzer'
                    },

                    target: '.is-user-dashboard .todos .users',
                    content: '<p>Aufgaben können nach Mitarbeitern gefiltert werden...</p>'
                },
                {
                    header: {
                        title: 'Suchmöglichkeit'
                    },

                    target: '.is-user-dashboard .todos .is-flex.level-left',
                    content: '<p>...nach Stichworten oder Reisenummern durchsucht werden...</p>'
                },
                {
                    header: {
                        title: 'Filtermöglichkeit: Status'
                    },
                    target: '.is-user-dashboard .todos .buttons.has-addons',
                    content: `<p>...und nach dem Status der Aufgabe gefiltert werden.</p>`
                },
                {
                    header: {
                        title: 'Aufgabe'
                    },
                    target: '.is-user-dashboard .todos .todo:first-child',
                    content: 'Falls eine Aufgabe einem Vorgang zugeordnet ist, wird die Reisenummer, der Kunde wie auch die Destination der Reise angezeigt. Wenn man über die Reisenummer fährt, werden weitere Details zur Aufgabe und Reise angezeigt. Über die drei Punkte kann die Aufgabe bearbeitet werden.'
                },
                {
                    header: {
                        title: 'Übersicht über Reisen'
                    },
                    target: '.is-user-dashboard .orders .level',
                    content: `
                        <p>Auf der linken Seite des Dashboards befinden sich alle Reisen, Leistungen und Anfragen.</p>
                    `,
                },
                {
                    header: {
                        title: 'Anzeigemöglichkeiten'
                    },
                    target: '[data-v-step="filters:type"]',
                    content: `
                        <p><i class="material-icons">folder</i>Reisen</p>
                        <p><i class="material-icons">local_hotel</i>Hotelleistungen mit den zugehörigen Reisen</p>
                        <p><i class="material-icons">directions_boat</i>Fährleistungen mit den zugehörigen Reisen</p>
                        <p><i class="material-icons">local_airport</i>Flugleistungen mit den zugehörigen Reisen</p>
                        <p><i class="material-icons">more_horiz</i>Zusatzleistungen mit den zugehörigen Reisen</p>
                    `,
                },
                {
                    header: {
                        title: 'Filtermöglichkeiten'
                    },
                    target: '[data-v-step="filters"]',
                    content: `
                        <ul>
                            <li>freie Suche nach Reisenummer, Kunde, etc.</li>
                            <li>Reisedestinationen</li>
                            <li>Reisestatus</li>
                            <li>Beginn der Reise</li>
                            <li>Mitarbeitern</li>
                        </ul>
                    `
                },
                {
                    header: {
                        title: 'Sortiermöglichkeiten'
                    },
                    target: '.is-user-dashboard .orders .row.is-head .cell.is-small-cell',
                    content: '<p>Alle Tabellen können mit einem Klick auf den Spaltennamen sortiert werden.</p>'
                },
                {
                    header: {
                        title: 'Besonderheiten in der Reisetabelle - Kunde und Gruppenname'
                    },
                    target: '[data-v-step="table:cell:client.shortName:2"]',
                    content: '<p>Hier wird der Kundenname sowie - falls vorhanden - der Gruppenname in Klammern angezeigt.</p>'
                },
                {
                    header: {
                        title: 'Besonderheiten in der Reisetabelle - Buchungsstand'
                    },
                    target: '[data-v-step="table:cell:hotelContingent:4"]',
                    content: '<p>Hier wird der aktuelle Buchungsstand der Reise angezeigt. Beim Drüberfahren wird zudem die Buchungsstand-Entwicklung angezeigt.</p>'
                },
                {
                    header: {
                        title: 'Besonderheiten in der Reisetabelle - Deadline'
                    },
                    target: '[data-v-step="table:cell:mostUrgentDueDate:2"]',
                    content: '<p>In dieser Spalte wird für jede Reise immer die nächste anstehende externe Deadline angezeigt (unabhängig vom Mitarbeiter). Externe Deadlines sind extern festgelegte Deadlines (wie zum Beispiel Optionstermine), die eingehalten werden sollten.</p>'
                },
                {
                    header: {
                        title: 'Besonderheiten in der Hoteltabelle - Hotelleistungen'
                    },
                    target: '.is-user-dashboard .orders .row.is-filters .level-item',
                    content: '<p>In Kombination mit dem Suchfeld können hier alle Anfragen zu einem bestimmten Hotel (von Hotel) oder alle Anfragen in einem gewissen örtlichen Raum (im Raum) angezeigt werden. Die Filter können ähnlich wie in der Reisetabelle benutzt werden. Einen besonderen Filter gibt es, wenn "im Raum" ausgewählt wurde. Dieser ermöglicht es zum Beispiel alle Reisen anzuzeigen, wo eine Hotelleistung angelegt, aber noch nicht angefragt wurde. </p>'
                },
                {
                    header: {
                        title: 'Besonderheiten in der Hoteltabelle - Export'
                    },
                    target: '.is-user-dashboard .orders .is-header .simple-menu--export',
                    content: '<p>Alle angezeigten Hotelleistungen mit den zugehörigen Reisen können über diesen Button zur Weiterverarbeitung in eine Excel Tabelle übertragen werden.</p>'
                },
                {
                    header: {
                        title: 'Besonderheiten in der Fährtabelle - Fährleistungen'
                    },
                    target: '.is-user-dashboard .orders .row.is-filters .level-item',
                    content: '<p>In Kombination mit dem Suchfeld können hier alle Anfragen zu einer bestimmten Fähre (von Fähre) oder alle Anfragen für eine Fährstrecke (für Fährstrecke) angezeigt werden. Die Filter können ähnlich wie in der Reisetabelle benutzt werden. Ähnlich funktioniert es für auch für Zusatz- und Flugleistungen.</p>'
                },
            ],

            callbacks: {
                onPreviousStep: this.previousCallback,
                onNextStep: this.nextCallback,
            },
        }
    },

    methods: {
        previousCallback: function(currentStep) {
            if (currentStep === 12) {
                this.$layout.vm.$emit('tour:user-dashboard:changeType', 'orders')
            }
            if (currentStep === 14) {
                this.$layout.vm.$emit('tour:user-dashboard:changeType', 'hotel')
            }
        },

        nextCallback: function(currentStep) {
            if (currentStep === 11) {
                this.$layout.vm.$emit('tour:user-dashboard:changeType', 'hotel')
            }
            if (currentStep === 13) {
                this.$layout.vm.$emit('tour:user-dashboard:changeType', 'ferry')
            }
        }
    }
}
</script>
