var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _c(
        "train-station-cell",
        _vm._b(
          {},
          "train-station-cell",
          { row: _vm.row, options: _vm.options, value: _vm.row.train },
          false
        )
      ),
      _vm._v(" "),
      _vm.agency || _vm.name
        ? [
            _vm.agency ? [_vm._v(_vm._s(_vm.agency))] : _vm._e(),
            _vm.hasSeparator ? [_vm._v(" - ")] : _vm._e(),
            _vm.name ? [_vm._v(_vm._s(_vm.name))] : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }