export default {
    props: {
        row: {
            type: Object,
            required: true
        },

        options: {
            type: Object
        }
    }
}