<template>
    <div class="level-left">
        <filter-default v-model="filters" v-bind:options="options" requests>
            <async-select
                api="destinations"
                placeholder="Destination filtern"
                v-model="filters.hotel.destination"
                class="is-smaller level-item"
            />
        </filter-default>
    </div>
</template>

<script>
import { AsyncSelect } from '@components/form'
import value, { options } from '../_mixins';
import FilterDefault from '../Default';

export default {
    mixins: [ value, options ],

    components: {
        FilterDefault,
        AsyncSelect
    }
}
</script>
