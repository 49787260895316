import Placeholders from './Placeholders';
import Requests from './Requests';
import { Provider } from '../_mixins';

export default {
    mixins: [ Provider ],

    labels: {
        requests: 'von Fähre',
        placeholders: 'für Fährstrecke',
    },

    components: {
        Placeholders,
        Requests
    }
}
