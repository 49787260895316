var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "level-left" },
    [
      _c(
        "div",
        { staticClass: "level-item" },
        [
          _c(
            "toggle-switch",
            {
              model: {
                value: _vm.isRequests,
                callback: function ($$v) {
                  _vm.isRequests = $$v
                },
                expression: "isRequests",
              },
            },
            [
              _c("option", { domProps: { value: true } }, [
                _vm._v(_vm._s(_vm.labels.requests)),
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: false } }, [
                _vm._v(_vm._s(_vm.labels.placeholders)),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isRequests
        ? _c("requests", {
            attrs: { options: _vm.options },
            model: {
              value: _vm.filters,
              callback: function ($$v) {
                _vm.filters = $$v
              },
              expression: "filters",
            },
          })
        : _c("placeholders", {
            attrs: { options: _vm.options },
            model: {
              value: _vm.filters,
              callback: function ($$v) {
                _vm.filters = $$v
              },
              expression: "filters",
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }