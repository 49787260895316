var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "level-left" },
    [
      _c(
        "filter-default",
        {
          attrs: { options: _vm.options, placeholders: "" },
          model: {
            value: _vm.filters,
            callback: function ($$v) {
              _vm.filters = $$v
            },
            expression: "filters",
          },
        },
        [
          _c("async-select", {
            staticClass: "is-smaller level-item",
            attrs: { api: "destinations", placeholder: "Destination filtern" },
            model: {
              value: _vm.filters.hotel.destination,
              callback: function ($$v) {
                _vm.$set(_vm.filters.hotel, "destination", $$v)
              },
              expression: "filters.hotel.destination",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }