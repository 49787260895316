var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "level-left" },
    [
      _c(
        "filter-default",
        {
          attrs: { options: _vm.options, requests: "" },
          model: {
            value: _vm.filters,
            callback: function ($$v) {
              _vm.filters = $$v
            },
            expression: "filters",
          },
        },
        [
          _c(
            "div",
            { staticClass: "level-left level-item" },
            [
              _c("async-select", {
                staticClass: "is-smaller level-item",
                attrs: {
                  api: "ports",
                  placeholder: "Abfahrtshafen",
                  "custom-label": (port) => port.place.name,
                },
                model: {
                  value: _vm.filters.ferry.startPort,
                  callback: function ($$v) {
                    _vm.$set(_vm.filters.ferry, "startPort", $$v)
                  },
                  expression: "filters.ferry.startPort",
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "level-item" }, [_vm._v("-")]),
              _vm._v(" "),
              _c("async-select", {
                staticClass: "is-smaller",
                attrs: {
                  api: "ports",
                  placeholder: "Ankunftshafen",
                  "custom-label": (port) => port.place.name,
                },
                model: {
                  value: _vm.filters.ferry.endPort,
                  callback: function ($$v) {
                    _vm.$set(_vm.filters.ferry, "endPort", $$v)
                  },
                  expression: "filters.ferry.endPort",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }