<template>
    <div class="level-left">
        <filter-default
            v-model="filters"
            v-bind:options="options"
            v-bind:search="false"
            placeholders
        >
            <div class="level-left level-item">
                <async-select
                    api="ports"
                    placeholder="Abfahrtshafen"
                    v-model="filters.ferry.startPort"
                    v-bind:custom-label="port => port.place.name"
                    class="is-smaller level-item"
                />

                <span class="level-item">-</span>

                <async-select
                    api="ports"
                    placeholder="Ankunftshafen"
                    v-model="filters.ferry.endPort"
                    v-bind:custom-label="port => port.place.name"
                    class="is-smaller"
                />
            </div>
        </filter-default>
    </div>
</template>

<script>
import value, { options } from '../_mixins';
import FilterDefault from '../Default';
import { AsyncSelect } from '@components/form';

export default {
    mixins: [ value, options ],

    components: {
        FilterDefault,
        AsyncSelect
    }
}
</script>
