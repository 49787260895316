<template>
    <layout>
        <div class="is-user-dashboard">
            <div>
                <orders
                    v-on:refreshTodos="refreshTodos"
                />
                <todos
                    ref="todosComponent"
                />
            </div>
            <tour />
        </div>
    </layout>
</template>

<script>
import Todos from './Todos';
import Orders from './Orders';
import Tour from './Tour'

export default {
    components: {
        Todos,
        Orders,
        Tour
    },

    methods: {
        refreshTodos: function(){
            this.$refs.todosComponent.fetchTodos();
        }
    }
}
</script>
