<template>
    <div class="level-left">
        <filter-default v-model="filters" v-bind:options="options" requests>
            <div class="level-left level-item">
                <async-select
                    api="airports"
                    placeholder="Abflug"
                    v-model="filters.airline.startAirport"
                    class="is-smaller level-item"
                />

                <span class="level-item">-</span>

                <async-select
                    api="airports"
                    placeholder="Ankunft"
                    v-model="filters.airline.endAirport"
                    class="is-smaller"
                />
            </div>
        </filter-default>
    </div>
</template>

<script>
import value, { options } from '../_mixins';
import FilterDefault from '../Default';
import { AsyncSelect } from '@components/form'

export default {
    mixins: [ value, options ],

    components: {
        FilterDefault,
        AsyncSelect
    }
}
</script>
