<template>
    <div class="row is-filters">
        <component
            :is="`${type}-filters`"
            v-model="filters"
            v-bind:options="options"
        />
    </div>
</template>

<script>
import HotelFilters from './Hotel';
import FerryFilters from './Ferry';
import TrainFilters from './Train';
import AirlineFilters from './Airline';
import OtherFilters from './Other'
import OrdersFilters from './Orders';

export default {
    props: {
        type: {
            type: String,
            required: true
        },

        options: {
            type: Object,
        },

        value: {
            type: Object,
            required: true,
        }
    },

    computed: {
        filters: {
            get: function () { return this.value },
            set: function (value) { this.$emit('input', {...this.value, ...value}) }
        }
    },

    components: {
        HotelFilters,
        FerryFilters,
        TrainFilters,
        AirlineFilters,
        OtherFilters,
        OrdersFilters
    }
}
</script>
