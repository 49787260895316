<template>
    <div class="level-left">
        <input
            type="text"
            v-model="_search"
            class="input is-medium level-item"
            placeholder="Suchen"
        >

        <i class="fa fa-filter fa-lg level-item b-l" ></i>

        <input
            type="text"
            v-model="filters.tripName"
            class="input is-medium level-item is-hidden-on-small"
            placeholder="Reisetitel oder ResaNr suchen"
        >

        <async-select
            api="destinations"
            v-model="filters.destinations"
            class="is-smaller level-item"
            placeholder="Destinationen"
            multiple
        />

        <multiselect
            track-by="label"
            label="name"
            class="is-smaller level-item is-hidden-on-small"

            v-bind:options="resources.countries"
            v-model="filterCountry"
            placeholder="Land"
        >
            <div
                slot="clear"
                class="multiselect__clear"
                v-if="!!filterCountry"
                v-on:click.prevent.stop="filterCountry = ''"
            ></div>
        </multiselect>

        <color-switch @input="setOrderStatus"></color-switch>

        <multiselect
            v-model="orderStatus"
            v-bind:options="options.orderStatus ? options.orderStatus : []"
            track-by="key"
            label="value"
            multiple
            class="is-smaller level-item"
            placeholder="Status filtern"
        >
            <template slot="selection" slot-scope="{ values, search, isOpen }">
                <span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} Stati ausgewählt</span>
            </template>
        </multiselect>

        <div class="flatpickr-combined level-item">
            <input-date v-model="filters.startAt" />
            <span>-</span>
            <input-date v-model="filters.endAt" />
        </div>

        <multiselect
            v-if="resources.organisations.length > 1"
            v-bind:options="resources.organisations"
            v-model="filterOrganisation"
            class="is-smaller level-item"
            track-by="id"
            label="name"
            placeholder="Organisation"
        >
            <div
                slot="clear"
                class="multiselect__clear"
                v-if="!!filterOrganisation"
                v-on:click.prevent.stop="filterOrganisation = ''"
            ></div>
        </multiselect>
    </div>
</template>

<script>
import value, { options } from './_mixins';
import debounce from 'lodash/debounce';
import { AsyncSelect, Multiselect, InputDate } from '@components/form'
import ColorSwitch from "@components/form/ColorSwitch";
import isEqual from "lodash/isEqual";

export default {
    mixins: [ value, options ],

    components: {
        ColorSwitch,
        AsyncSelect,
        Multiselect,
        InputDate
    },

    computed: {
         _search: {
            get: function () { return this.filters._search },
            set: debounce(function (value) {
                this.$emit('input', {...this.value, _search: value})
            }, 600)
        }
    },

    methods: {
        setOrderStatus: function (value) {

            let inActives = ['deleted', 'offer_declined', 'booking_cancelled']
            let oldStatuses = this.options.orderStatus.filter(item => this.filters.orderStatus.includes(item.key));
            let newStatuses = this.options.orderStatus.filter(item => value === 'is-green' ? !inActives.includes(item.key) : inActives.includes(item.key));



            this.orderStatus = isEqual(oldStatuses, newStatuses) ? [] : newStatuses;
        }
    }
}
</script>
